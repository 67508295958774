import {
  FieldValuesFromFieldErrors,
  ErrorMessage as HookFormErrorMessage,
} from '@hookform/error-message'
import { Box } from '@mui/material'

import type {
  ValidateResult,
  DeepRequired,
  FieldErrorsImpl,
  FieldValues,
  FieldName,
} from 'react-hook-form'

type ErrorMessageDisplayProps = {
  key?: string
  message: ValidateResult
}
type ErrorMessageProps<T = FieldValues> = {
  errors?: FieldErrorsImpl<DeepRequired<T>>
  name: FieldName<FieldValuesFromFieldErrors<FieldErrorsImpl<DeepRequired<T>>>>
}

const ErrorMessageDisplay = ({
  key = '',
  message,
}: ErrorMessageDisplayProps) => (
  <Box
    key={key}
    component="p"
    sx={{ color: 'error.main', marginTop: '5px', fontSize: '12px' }}
  >
    {message}
  </Box>
)

export const ErrorMessage = <T,>({ errors, name }: ErrorMessageProps<T>) => (
  <HookFormErrorMessage
    errors={errors}
    name={name}
    render={({ message, messages }) =>
      (messages &&
        Object.entries(messages).map(([type, msg]) => (
          <ErrorMessageDisplay key={type} message={msg} />
        ))) || <ErrorMessageDisplay message={message} />
    }
  />
)
