import { Box } from '@mui/material'
import Image from 'next/legacy/image'
import { ReactElement } from 'react'

import {
  StyledBackgroundImage,
  StyledTemplateWrapper,
} from '@/components/templates/user/Login/style'

export const LoginTemplate = ({ children }: { children: ReactElement }) => (
  <StyledTemplateWrapper>
    <Box>{children}</Box>
    <StyledBackgroundImage>
      <Image
        src="/images/login_background.png"
        layout="fill"
        objectFit="cover"
        alt="login_background"
        priority
      />
    </StyledBackgroundImage>
  </StyledTemplateWrapper>
)
