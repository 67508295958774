import { Box, Card, styled } from '@mui/material'

import { Button } from '@/components/uiParts/Button/BasicButton'

export const StyledCard = styled(Card)({
  width: '414px',
  minHeight: '348px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 99999,
  padding: '40px 24px',
})

export const StyledTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: '20px',
  fontWeight: 'bold',
  textAlign: 'center',
}))

export const StyledLoginButton = styled(Button)({
  width: '100%',
  height: '44px',
  fontSize: '14px',
  fontWeight: 'bold',
})
