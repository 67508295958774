/**
 * react-hook-form のエラーメッセージを取得します.
 * 入力フィールドの name 属性を `.` で分割し, `errors` を再起的に問い合わせます.
 *
 * @param fieldErrors
 * @param fieldName
 * @returns
 */
export const getErrorRecursive = (
  fieldErrors: Record<string, unknown>,
  fieldName: string,
) => {
  const keys = fieldName.split('.')
  let result = fieldErrors

  keys.forEach((key) => {
    if (result == null) return
    result = result[key] as Record<string, unknown>
  })
  return result
}
