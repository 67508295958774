import { Box, styled } from '@mui/material'

export const StyledTemplateWrapper = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  height: '100vh',
  backgroundColor: theme.palette.base.main,
}))

export const StyledBackgroundImage = styled(Box)({
  position: 'absolute',
  bottom: 0,
  width: '100vw',
  height: '57%',
  pointerEvents: 'none',
})
