import Link from 'next/link'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useLogin } from '@/components/organisms/user/login/card/hooks'
import {
  StyledCard,
  StyledLoginButton,
  StyledTitle,
} from '@/components/organisms/user/login/card/style'
import { LabelingInput } from '@/components/uiParts/Input/LabelingInput'
import { TextField } from '@/components/uiParts/Input/TextField'
import { StackColumn } from '@/components/uiParts/Stack/Column'
import { PASSWORD_MIN_LENGTH } from '@/constants/constants'
import CheckIcon from '@/icons/check.svg'
import { stringFormat } from '@/utils/stringUtils'
import { validEmailFormat } from '@/utils/validation/common/validation'

export const LoginCard = () => {
  const { t } = useTranslation()
  const { formMethods, login } = useLogin()
  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods

  return (
    <StyledCard>
      <StackColumn spacing={4}>
        <StyledTitle>Log in to PORTRICH</StyledTitle>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(login)}>
            <StackColumn spacing={3}>
              <LabelingInput label="Email">
                <TextField
                  name="email"
                  rules={{
                    required: t('validation.required', {
                      text: t('email_address'),
                    }),
                    validate: (value: string) =>
                      validEmailFormat(value) ||
                      t('invalid_format', { text: t('email_address') }),
                  }}
                />
              </LabelingInput>
              <LabelingInput label="Password">
                <TextField
                  type="password"
                  name="password"
                  rules={{
                    required: t('validation.required', {
                      text: t('password'),
                    }),
                    minLength: {
                      value: PASSWORD_MIN_LENGTH,
                      message: stringFormat(
                        t('validation.password_requirements'),
                        String(PASSWORD_MIN_LENGTH),
                      ),
                    },
                  }}
                />
              </LabelingInput>
              <StyledLoginButton
                type="submit"
                startIcon={<CheckIcon />}
                disabled={isSubmitting}
              >
                Log in
              </StyledLoginButton>
            </StackColumn>
          </form>
        </FormProvider>
        <Link href="/user/password">{t('forgot_your_password')}</Link>
      </StackColumn>
    </StyledCard>
  )
}
