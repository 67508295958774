/**
 * Python like string format.
 *
 * Example:
 * >>> stringFormat('Hello, {0}!', 'world')
 * >>> > 'Hello, world!'
 *
 * @param str string including format like {0}, {1}, ...
 * @param args arguments to replace
 * @returns
 */
export const stringFormat = (str: string, ...args: string[]) => {
  return str.replace(/{(\d+)}/g, (match, num: number) => {
    return typeof args[num] !== 'undefined' ? args[num] : match
  })
}

export const formatBooleanForDisplay = (value: boolean | undefined) => {
  if (value === undefined) return '-'
  if (value) {
    return 'YES'
  }
  return 'NO'
}
