import { FormControl, TextFieldProps as MuiTextFieldProps } from '@mui/material'
import { memo } from 'react'
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form'

import { ErrorMessage } from '@/components/organisms/error/ErrorMessage'
import { StyledTextField } from '@/components/uiParts/Input/TextField/style'
import { getErrorRecursive } from '@/utils/react_hook_form'

export type TextFieldProps = MuiTextFieldProps & {
  name: string
  rules?: RegisterOptions
}

export const TextField = memo((props: TextFieldProps) => {
  const { name, rules, onChange, onBlur, inputProps, ...rest } = props
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const error = rest.error || Boolean(getErrorRecursive(errors, name))

  return (
    <FormControl error={error} sx={{ width: '100%' }}>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <StyledTextField
            {...rest}
            {...field}
            fullWidth
            error={error}
            value={field.value ?? ''}
            inputProps={{ ...inputProps, 'aria-label': name }}
            onChange={(event) => {
              field.onChange(event)
              if (onChange) onChange(event)
            }}
            onBlur={(event) => {
              field.onBlur()
              if (onBlur) onBlur(event)
            }}
          />
        )}
      />
      <ErrorMessage errors={errors} name={name} />
    </FormControl>
  )
})

TextField.displayName = 'TextField'
