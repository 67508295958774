import { ThemeProvider } from '@mui/material'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/react'
import { useContext, useEffect } from 'react'

import { LoginCard } from '@/components/organisms/user/login/card'
import { LoginTemplate } from '@/components/templates/user/Login'
import { Loading } from '@/components/uiParts/Loading'
import { USER_ROOT_URL } from '@/constants/constants'
import { I0010 } from '@/constants/messages'
import { SnackbarContext } from '@/contexts/Snackbar'
import theme from '@/styles/user/theme'

const Login: NextPage = () => {
  const { status } = useSession()
  const { snackbarDispatch } = useContext(SnackbarContext)
  const router = useRouter()

  useEffect(() => {
    // 認証済みの場合、topページにリダイレクト
    if (status === 'authenticated') {
      const callbackUrl = router.query.callbackUrl
        ? String(router.query.callbackUrl)
        : USER_ROOT_URL
      router.push(callbackUrl)
    } else if (router.query.error === 'SessionRequired') {
      snackbarDispatch({ message: I0010(), severity: 'error' })
    }
  }, [router, status])

  // 読み込み中 or 認証済みの場合
  if (status !== 'unauthenticated') return <Loading />

  return (
    <ThemeProvider theme={theme}>
      <LoginTemplate>
        <LoginCard />
      </LoginTemplate>
    </ThemeProvider>
  )
}

export default Login
