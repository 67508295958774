import { useRouter } from 'next/router'
import { signIn } from 'next-auth/react'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'

import { USER_ROOT_URL } from '@/constants/constants'
import { E0004 } from '@/constants/messages'
import { SnackbarContext } from '@/contexts/Snackbar'

type LoginForm = { email: string; password: string }

/** ログイン用 */
export const useLogin = () => {
  const router = useRouter()
  const formMethods = useForm<LoginForm>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  })
  const { snackbarDispatch } = useContext(SnackbarContext)
  const login = async (data: LoginForm) => {
    const callbackUrl = router.query.callbackUrl
      ? String(router.query.callbackUrl)
      : USER_ROOT_URL
    const result = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    })
    // signInは認証に失敗したときもresolveされる
    // ref: https://next-auth.js.org/getting-started/client#signin
    if (result?.ok) {
      router.replace(callbackUrl)
    } else {
      snackbarDispatch({ message: E0004(), severity: 'error' })
    }
  }

  return {
    formMethods,
    login,
  }
}
