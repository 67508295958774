import { styled, TextField } from '@mui/material'

export const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    '&:hover': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  '& .MuiInputBase-input.MuiOutlinedInput-input': {
    padding: '0',
  },
  '& .MuiOutlinedInput-root': {
    padding: '12px 16px',
    backgroundColor: theme.palette.common.white,
  },
}))
