/** メール形式チェック */
export const validEmailFormat = (item: string) => {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return regex.test(item)
}

/** 電話形式チェック */
export const validTell = (item: string) => {
  const regex = /^[0-9]{9,12}$/
  return regex.test(item)
}

/** 同値チェック */
export const validEquivalent = (item: string, item2: string) => {
  if (item === item2) {
    return true
  }
  return false
}
