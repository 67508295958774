// 情報系メッセージ
/** 認証切れ */
export const I0010 = () =>
  'セッションの有効期限が切れました。再ログインをお願いいたします。'
// エラー系メッセージ
export const E0001 = (val0: string) => `${val0}は必須入力項目です。`
export const E0002 = (val0: string) => `${val0}が正しい形式ではありません。`
export const E0003 = (val0: string, val1: number) =>
  `${val0}は${val1}文字以上で入力してください。`
export const E0004 = () => 'メールアドレスまたはパスワードが間違っています。'
export const E0005 = () =>
  '現在メッセージは送れません。\nご迷惑をおかけしますが復旧までしばらくお待ち下さい。'
export const E0006 = (val0: string | number) =>
  `${val0}文字以下で入力してください。`
export const E0007 = (val0: string | number) =>
  `${val0}以上の数値を入力してください。`
export const E0009 = (val0: string | number, val1: string | number) =>
  `${val0}以上, ${val1}以下の数値を入力してください。`
