import { Box, InputLabel, InputLabelProps, Stack } from '@mui/material'
import { ReactNode } from 'react'

export type Props = Omit<InputLabelProps, 'children'> & {
  label?: ReactNode
  anchor?: 'top' | 'left'
  children: ReactNode
  required?: boolean
}

export const LabelingInput = (props: Props) => {
  const { label, children, anchor = 'top', required, ...rest } = props

  return (
    <Stack
      spacing={1}
      direction={anchor === 'top' ? 'column' : 'row'}
      alignItems={anchor === 'top' ? 'start' : 'center'}
    >
      <Box>
        <InputLabel sx={{ color: 'text.primary', fontSize: '12px' }} {...rest}>
          {required ? (
            <Box>
              {label} <span style={{ color: 'red' }}>*</span>
            </Box>
          ) : (
            label
          )}
        </InputLabel>
      </Box>
      <Box flexGrow={1} sx={{ width: '100%' }}>
        {children}
      </Box>
    </Stack>
  )
}
